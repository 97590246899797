@charset "utf-8";
/* CSS Document */

@import url("https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.1/css/lightbox.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

body {
  margin: 0;
  padding: 0;
  font-family: "GeneralSans-Regular" !important;
  font-size: 14px;
  color: #3a3a3a;
  font-weight: 400;
  position: relative;
  background-color: #ffffff !important;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
nav,
figure {
  position: relative;
}

ul,
li,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none !important;
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
  max-width: 100%;
}

input[type="date"].form-control {
  text-transform: uppercase;
}

table {
  width: 100%;
}
p {
  margin: 0;
  padding: 0;
}
.fltL {
  float: left;
}
.fltR {
  float: right !important;
}
.padding_none {
  padding: 0 !important;
}
.border_none {
  border: none !important;
}
.navbar-toggle {
  background-color: #fff !important;
}
.icon-bar {
  background-color: #000 !important;
}
.p_right0 {
  padding-right: 0px !important;
}
.p_left0 {
  padding-left: 0px !important;
}

.CheckBox {
  display: inline-block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  color: #2d2d2d;
  margin: 0;
  min-height: 20px;
}
.CheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.CheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #6495ed;
  border-radius: 2px;
}
.CheckBox:hover input ~ .checkmark {
  background-color: #6495ed;
  border-color: #6495ed;
}
.CheckBox input:checked ~ .checkmark {
  background-color: #6495ed;
}
.CheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 2px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.CheckBox input:checked ~ .checkmark:after {
  display: block;
}

.CheckName {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
}

header .Header {
  background-color: #6495ed;
  padding: 12px 0;
}
header .Header .Social {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .Header .Social ul li {
  display: inline-block;
  margin: 0 30px 0 0;
  position: relative;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.4px;
}
header .Header .Social ul li span {
  height: 16px;
  display: inline-block;
  margin: 0 5px 0 0;
}
header .Header .Social ul li span img {
  height: 15px;
}
header .Header .Social ul li + li:before {
  content: "|";
  position: absolute;
  left: -20px;
  color: #fff;
}
header .Header .Social ol li {
  display: inline-block;
  margin: 0 0 0 15px;
}
header .Header .Social ol li a {
  color: #fff;
  font-size: 13px;
}
header .Navigation {
  padding: 10px 0;
}
header .Navigation .navbar {
  padding: 0;
}
header .Navigation .navbar .navbar-brand {
  margin: 0 40px 0 0;
  padding: 0;
  max-width: 120px;
}
header .Navigation .navbar .navbar-collapse {
  justify-content: end;
}
header .Navigation .navbar .navbar-nav li .nav-link {
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  position: relative;
}
header .Navigation .navbar .navbar-nav li.Login a {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #6495ed;
  background-color: #e8f0ff;
  border-radius: 50px;
}
header .Navigation .navbar .navbar-nav li.Login a span {
  margin: 0 5px 0 0;
}

header .Navigation .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  font-weight: 500;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  padding: 10px 1.5rem;
}
header
  .Navigation
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  .NewDropdownBox
  .dropdown-item {
  width: calc(100% / 2);
}
header
  .Navigation
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  .dropdown-item:hover {
  color: #6495ed;
}
/* header .Navigation .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:active {background-color: #f8f9fa;} */
header .Navigation .navbar .navbar-nav .nav-item.dropdown a span {
  display: inline-block;
  margin: 0 0 0 5px;
  /* width: 36px; */
}

header .Navigation .navbar .navbar-nav .nav-item .dropdown-menu {
  width: 300px;
  right: 0 !important;
  left: auto !important;
}
header
  .Navigation
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  .NewDropdownBox {
  display: flex;
  flex-wrap: wrap;
}
header
  .Navigation
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  .BookConsultation {
  padding: 0 10px;
}
header
  .Navigation
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  .BookConsultation
  a {
  border-radius: 30px;
  text-align: center;
  background-color: #6495ed;
  color: #fff;
  margin: 0 auto;
  margin: 10px auto;
}
header
  .Navigation
  .navbar
  .navbar-nav
  .nav-item
  .dropdown-menu
  .BookConsultation
  a:hover {
  color: #fff;
}

header .Header .Social ol li.Login a {
  padding: 8px 20px;
}
header .Header .Social ol li.Login a {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #6495ed;
  background-color: #e8f0ff;
  border-radius: 50px;
}
header .Header .Social ol li.Login span {
  margin: 0 5px 0 0;
}

.NewHeader .navbar-nav {
  display: flex;
  align-items: center;
}
.NewHeader header .Navigation {
  padding: 10px 0;
}
.NewHeader header .Navigation {
  box-shadow: 0 0 30px 0 rgba(7, 204, 236, 0.05);
}

.Small-Wrapper {
  background-color: #fff;
  padding: 30px 20px 50px;
}

.SlideArea {
  background-color: #e9f1ff;
}
.SlideArea .SlideBox {
  display: flex;
  justify-content: space-between;
}
.SlideArea .SlideBox .SlideLeft {
  padding: 70px 0 40px;
}
.SlideArea .SlideBox .SlideLeft h5 {
  color: #fc2e5a;
  border-left: 2px solid #fc2e5a;
  line-height: 15px;
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
  font-size: 14px;
}
.SlideArea .SlideBox .SlideLeft h4 {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  line-height: 43px;
  margin: 0 0 35px 0;
}
.SlideArea .SlideBox .SlideLeft a {
  background-color: #6495ed;
  color: #fff;
  display: inline-block;
  padding: 15px 35px;
  border-radius: 50px;
  font-weight: 500;
}
.SlideArea .SlideBox .SlideLeft aside {
  margin: 50px 0 0 0;
}
.SlideArea .SlideBox .SlideLeft aside h6 {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0 0 15px 0;
}
.SlideArea .SlideBox .SlideLeft aside img {
  max-width: 130px;
}
.SlideArea .SlideBox .SlideRight {
  max-width: 600px;
}

.MembershipArea {
  background-color: #f5f6f6;
  padding: 50px 0 20px 0;
}
.MembershipArea .MembershipHead {
  text-align: center;
  margin: 0 0 50px 0;
}
.MembershipArea .MembershipHead p {
  color: #fc2e5a;
  border-left: 2px solid #fc2e5a;
  line-height: 15px;
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
}
.MembershipArea .MembershipHead h3 {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  line-height: 32px;
}
.MembershipArea .MembershipBody {
  position: relative;
}
.MembershipArea .MembershipBody .owl-carousel .owl-nav .owl-prev,
.MembershipArea .MembershipBody .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 39%;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 40px;
  box-shadow: 0 0 10px #ddd;
  font-size: 23px;
  outline: 0;
}
.MembershipArea .MembershipBody .owl-carousel .owl-nav .owl-prev {
  left: -20px;
}
.MembershipArea .MembershipBody .owl-carousel .owl-nav .owl-next {
  right: -20px;
}
.MembershipArea .MembershipBody .item {
  padding: 0 0 50px 0;
}
.MembershipArea .MembershipBox {
  background-color: #fff;
  text-align: center;
  padding: 50px 40px;
  border-radius: 10px;
  position: relative;
  transition: 0.5s all ease-in-out;
}
.MembershipArea .MembershipBox .Icon {
  display: inline-block;
}
.MembershipArea .MembershipBox .Icon img {
  max-height: 60px;
  width: auto;
}
.MembershipArea .MembershipBox h5 {
  color: #333;
  font-size: 16px;
  margin: 25px 0 15px 0;
  font-weight: 700;
}
.MembershipArea .MembershipBox p {
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
}
.MembershipArea .MembershipBox a {
  position: absolute;
  bottom: -20px;
  width: 40px;
  height: 40px;
  background-color: #fc2e5a;
  border-radius: 50%;
  line-height: 40px;
  color: #fff;
  font-size: 25px;
  transition: 0.5s all ease-in-out;
  transform: scale(0);
}
.MyMessageWImg {
  background-color: #e1d5fe;
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  margin-left: auto;
  max-width: 350px;
  text-align: right;
}

.MembershipArea .MembershipBox:hover {
  box-shadow: 0 5px 20px 0 #4285f40d;
  background-color: #6495ed;
}
.MembershipArea .MembershipBox:hover a {
  transform: scale(1);
}
.MembershipArea .MembershipBox:hover span {
  filter: brightness(0) invert(1);
}
.MembershipArea .MembershipBox:hover h5 {
  color: #fff;
}
.MembershipArea .MembershipBox:hover p {
  color: #fff;
}

.LocationArea {
  background-color: #fff;
  padding: 50px 0 30px 0;
}
.LocationArea .LocationHead {
  text-align: center;
  margin: 0 0 30px 0;
}
.LocationArea .LocationHead .container {
  position: relative;
}
.LocationArea .LocationHead select {
  float: right;
  background-color: #f5f5f5;
  padding: 9px 10px;
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  position: absolute;
  right: 0;
}
.LocationArea .LocationHead p {
  color: #fc2e5a;
  border-left: 2px solid #fc2e5a;
  line-height: 15px;
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
}
.LocationArea .LocationHead h3 {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  line-height: 32px;
}
.LocationArea .LocationBody {
  position: relative;
}
.LocationArea .LocationBody .item {
  padding: 20px 10px;
}

.LocationBox {
  background: #f5f6f6;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 23px #dfdfdf;
}
.LocationBox figure {
  margin: 0 0 10px 0;
}
.LocationBox figcaption h4 {
  font-size: 18px;
  font-weight: 600;
}
.LocationBox figcaption h6 {
  font-size: 13px;
  margin: 0 0 15px 0;
  font-weight: 600;
  color: #525252;
}
.LocationBox figcaption p {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
}
.LocationBox figcaption p span {
  width: 20px;
  display: inline-block;
  margin: 0 5px 0 0;
}
.LocationBox figcaption p strong {
  font-weight: 600;
}

.ExpertArea {
  background-color: #fff;
  padding: 50px 0 80px 0;
}
.ExpertArea .ExpertHead {
  text-align: center;
  margin: 0 0 30px 0;
}
.ExpertArea .ExpertHead p {
  color: #fc2e5a;
  border-left: 2px solid #fc2e5a;
  line-height: 15px;
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
}
.ExpertArea .ExpertHead h3 {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  line-height: 32px;
}
.ExpertArea .ExpertBody {
  position: relative;
}

.ExpertBox {
  position: relative;
}
.ExpertBox figure {
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
}
.ExpertBox figcaption {
  width: 90%;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 0 10px #ddd;
  border-radius: 10px;
  text-align: center;
  padding: 20px 10px;
  bottom: -30px;
  position: absolute;
  left: 0;
  right: 0;
}
.ExpertBox figcaption h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 5px 0;
  color: #000;
}
.ExpertBox figcaption p {
  color: #566d89;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.ExpertBox figcaption ol {
  width: 100%;
  text-align: center;
  display: none;
}
.ExpertBox figcaption ol li {
  display: inline-block;
  margin: 0 5px;
}
.ExpertBox figcaption ol li a {
  width: 37px;
  height: 37px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 38px;
  font-size: 15px;
  display: block;
  color: #5343df;
}
.ExpertBox:hover figcaption {
  background-color: #fc2e5a;
}
.ExpertBox:hover figcaption h3 {
  color: #fff;
}
.ExpertBox:hover figcaption p {
  color: #fff;
}
.ExpertBox:hover figcaption ol {
  display: block;
}

.ApplicationArea {
  display: flex;
  background-color: #e9f1ff;
  align-items: center;
}
.ApplicationArea figure {
  width: 50%;
  margin: 0;
  background-color: #6495ee;
  text-align: center;
}
.ApplicationArea figure img {
  max-width: 70%;
}
.ApplicationArea figcaption {
  width: 25%;
  margin: 0 0 0 70px;
}
.ApplicationArea figcaption h6 {
  color: #fc2e5a;
  border-left: 2px solid #fc2e5a;
  line-height: 15px;
  padding: 0 0 0 10px;
  margin: 0 0 20px 0;
  font-size: 14px;
  font-weight: 600;
}
.ApplicationArea figcaption h4 {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  line-height: 32px;
}
.ApplicationArea figcaption p {
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
  margin: 0 0 30px 0;
}
.ApplicationArea figcaption .form-group {
  position: relative;
  margin: 0;
}
.ApplicationArea figcaption .form-group label {
  position: absolute;
  left: 20px;
  top: -10px;
  background-color: #e9f1ff;
  padding: 0 10px;
  color: #6495ed;
  font-weight: 500;
}
.ApplicationArea figcaption .form-group .form-control {
  border-radius: 7px;
  border: 2px solid #6495ed;
  outline: 0;
  height: auto;
  font-size: 14px;
  padding: 15px 17px 12px;
  box-shadow: none;
  background-color: #e9f1ff;
  color: #6495ed;
}

.DownloadArea {
  padding: 50px 0;
}
.DownloadArea .DownloadLeft {
  padding: 0 0 0 60px;
}
.DownloadArea .DownloadLeft h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  margin: 0 0 30px 0;
}
.DownloadArea .DownloadLeft ul {
  padding-left: 20px;
  margin-bottom: 30px;
}
.DownloadArea .DownloadLeft ul li {
  margin: 0 0 15px 0;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  list-style: disc;
}
.DownloadArea .DownloadLeft ul li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
}
.DownloadArea .DownloadLeft a {
  display: inline-block;
  max-width: 180px;
  margin: 0 15px 0 0;
}
.DownloadArea .DownloadRight {
  text-align: center;
}
.DownloadArea .DownloadRight img {
  width: 65%;
  margin: auto;
}

footer {
  background-color: #6495ed;
}
footer .Footer {
  padding: 50px 0;
}
footer .Footer .Foots figure {
  max-width: 180px;
}
footer .Footer .Foots p {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  line-height: 27px;
}
footer .Footer .Foots ol {
  margin: 40px 0 0 0;
}
footer .Footer .Foots ol li {
  display: inline-block;
  margin: 0 10px 0 0;
}
footer .Footer .Foots ol li a {
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 35px;
  color: #6495ed;
  font-size: 15px;
  transition: 0.5s all ease-in-out;
  display: block;
  text-align: center;
}
footer .Footer .Foots h3 {
  font-size: 18px;
  margin: 0 0 30px 0;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.5px;
}
footer .Footer .Foots ul li {
  margin: 0 0 15px 0;
}
footer .Footer .Foots ul li a {
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
}
footer .Footer .Foots h6 {
  font-size: 14px;
  padding: 0 0 0 30px;
  margin: 0 0 16px 0;
  color: #fff;
  font-weight: 500;
  position: relative;
  min-height: 25px;
}
footer .Footer .Foots h6 span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
}
footer .Copyright {
  text-align: center;
  border-top: 1px solid rgb(255 255 255 / 10%);
  padding: 15px 0;
}
footer .Copyright p {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.ModalBox .modal-dialog {
  max-width: 475px;
}
.ModalBox .modal-dialog .modal-content {
  border: none;
  box-shadow: none;
  border-radius: 10px;
}
.ModalBox .modal-dialog .modal-content .modal-body {
  padding: 30px;
  position: relative;
}

.LoginBox {
  padding: 0 35px;
}
.CloseModal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #fff0;
  border: none;
  font-size: 23px;
  line-height: 10px;
  color: #7c7a7a;
  z-index: 99;
}
.LoginBox .LoginHead {
  text-align: center;
  margin: 0 0 20px 0;
}
.LoginBox .LoginHead img {
  width: 80px;
}
.LoginBox .LoginHead.Doctor img {
  width: 120px;
}
.LoginBox .LoginHead h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0 7px 0;
  letter-spacing: 0.5px;
}
.LoginBox .LoginHead p {
  font-size: 13px;
  line-height: 23px;
  color: #5a5656;
}
.LoginBox .LoginBody {
  padding: 25px;
  box-shadow: 0 -4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 25px;
  margin: 0 0 25px 0;
}
.LoginBox .LoginBody article {
  text-align: center;
  margin: 0 0 25px 0;
}
.LoginBox .LoginBody article h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-family: "GeneralSans-Bold";
}
.LoginBox .LoginBody article p {
  font-size: 13px;
  line-height: 23px;
  color: #5a5656;
}
.LoginBox .LoginBody .form-group {
  margin: 0 0 17px 0;
  position: relative;
}
.LoginBox .LoginBody .form-group .form-control::placeholder {
  color: #fff;
}
.LoginBox .LoginBody .form-group label {
  margin: 0;
  background-color: #fff;
  pointer-events: none;
  transition: all 0.2s;
  padding: 0 9px;
  position: absolute;
  top: 12px;
  left: 10px;
  font-size: 13px;
  font-weight: 500;
}
.LoginBox .LoginBody .form-group .form-control {
  height: auto;
  padding: 12px 17px;
  border: 1px solid #aaa;
  border-radius: 0.25rem;
  font-size: 14px;
  box-shadow: none;
  color: #000;
}
.LoginBox .LoginBody .form-group .form-control:focus + label {
  top: -9px;
}
.LoginBox .LoginBody .form-group .Icon {
  position: absolute;
  top: 12px;
  width: 20px;
  right: 10px;
}
.LoginBox .LoginBody .form-group h5 {
  text-align: center;
  margin: 0;
}
.LoginBox .LoginBody .form-group h5 a {
  font-size: 13px;
  color: #000;
  letter-spacing: 0.2px;
  font-weight: 700;
}
.LoginBox .LoginBody .ButtonBox button {
  width: 100%;
  border: none;
  outline: 0;
  padding: 13px 0;
  border-radius: 65px;
  margin: 0 0 15px 0;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.LoginBox .LoginBody .ButtonBox button:last-child {
  margin: 0;
}
.LoginBox .LoginBody .ButtonBox button.Dark {
  background-color: #6495ed;
  color: #fff;
}
.LoginBox .LoginBody .ButtonBox button.Light {
  background-color: #e9f1ff;
  color: #6495ed;
}
.LoginBox .LoginBody .ButtonBox button.Gray {
  background-color: #bfbfbf;
  color: #fff;
}
.LoginBox .LoginBody aside {
  margin: 25px 0 0 0;
  text-align: center;
}
.LoginBox .LoginBody aside h6 {
  margin: 0;
  color: #555;
  font-size: 14px;
  font-weight: 500;
}
.LoginBox .LoginBody aside h6 a {
  color: #000;
  font-weight: 700;
  letter-spacing: 0.2px;
}
.LoginBox .LoginBody aside h6 strong {
  color: #fc2e5a;
  margin: 0 5px;
}
.LoginBox .LoginBody .form-group .OTPBox {
  display: flex;
}
.LoginBox .LoginBody .form-group .OTPBox .form-control {
  padding: 10px 0;
  text-align: center;
  margin: 0 3px;
  font-size: 20px;
  color: #000;
  font-weight: 500;
}

.LoginBox .AccountCreatBody article {
  text-align: center;
  margin: 0 0 25px 0;
}
.LoginBox .AccountCreatBody article h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-family: "GeneralSans-bold";
}
.LoginBox .AccountCreatBody article h4 {
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 10px 0;
  line-height: 24px;
}
.LoginBox .AccountCreatBody article p {
  font-size: 14px;
  line-height: 23px;
  color: #5a5656;
}
.LoginBox .StepHead {
  text-align: center;
  margin: 25px 0 25px 0;
}

.StepBodyArea ul li {
  background-color: #fff;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 30px 20px 30px 115px;
  position: relative;
  border-radius: 24px;
  margin: 0 0 20px 0;
}
.StepBodyArea ul li span {
  position: absolute;
  background-color: rgba(251, 46, 90, 1);
  border-radius: 16px;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 74px;
  height: 74px;
  line-height: 74px;
  text-align: center;
}
.StepBodyArea ul li span.GreenCheck {
  width: 25px;
  height: 25px;
  color: #fff;
  background-color: #3fdf3d;
  border-radius: 50;
  line-height: 25px;
  text-align: center;
  top: 50%;
  right: 20px;
  position: absolute;
  left: inherit;
}
.StepBodyArea h5 {
  color: rgba(51, 51, 51, 1);
  font-weight: 700;
  font-family: "GeneralSans-Bold";
  margin-bottom: 0px;
  font-size: 17px;
}
.StepBodyArea p {
  color: rgba(85, 85, 85, 1);
  font-size: 15px;
  font-family: "GeneralSans-Regular";
  letter-spacing: 0.3px;
}

.SelectGender {
  margin: -5px 0 20px 0;
}
.SelectGender label {
  margin: 0 !important;
  padding: 0 !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin: 0 0 5px 0 !important;
}
.SelectGender ul {
  display: flex;
  justify-content: space-between;
}
.SelectGender ul li {
  width: 100%;
  position: relative;
}
.SelectGender ul li:first-child {
  margin: 0 10px 0 0;
}
.SelectGender ul li:last-child {
  margin: 0 0 0 10px;
}
.SelectGender ul li aside {
  border: 1px solid #aaa;
  margin-top: 0px !important;
  cursor: pointer;
  position: relative;
  border-radius: 0.25rem;
  padding: 12px 17px;
  color: #000;
}
.SelectGender ul li input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}
.SelectGender ul li input:checked ~ aside {
  background-color: #6495ed;
  color: #fff;
  border-color: #6495ed;
}
.SelectGender figure {
  margin-bottom: 0px;
}
.ImageSize {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ImageSize h4 {
  color: rgba(82, 90, 102, 1);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
}
.ImageSize p {
  color: rgba(170, 175, 182, 1);
  font-size: 14px;
  font-weight: 500;
}
.Button.Plus {
  border: none;
  padding: 10px 16px;
  text-align: center;
  transition: 0.5s all ease-in-out;
  font-weight: 700;
  position: relative;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  border-radius: 5px;
  outline: 0 !important;
  box-shadow: none;
  background: #6495ed;
  position: relative;
}
.Button.Plus input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  width: 100%;
}
.HippaCompliance h6 {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.HippaCompliance p {
  color: rgba(85, 85, 85, 1);
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 20px 0;
}

.HippaCompliance .ButtonBox button {
  width: 100%;
  border: none;
  outline: 0;
  padding: 15px 0;
  border-radius: 65px;
  margin: 0 0 15px 0;
  font-weight: 600;
}
.HippaCompliance .ButtonBox button:last-child {
  margin: 0;
}
.HippaCompliance .ButtonBox button.Dark {
  background-color: #6495ed;
  color: #fff;
}

.ButtonBox.ButtonBoxNew button {
  width: 100%;
  border: none;
  outline: 0;
  padding: 13px 0;
  border-radius: 65px;
  margin: 0 0 15px 0;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.ButtonBox.ButtonBoxNew button:last-child {
  margin: 0;
}
.ButtonBox.ButtonBoxNew button.Dark {
  background-color: #6495ed;
  color: #fff;
}
.ButtonBox.ButtonBoxNew button.Light {
  background-color: #e9f1ff;
  color: #6495ed;
}
.ButtonBox.ButtonBoxNew button.Gray {
  background-color: #bfbfbf;
  color: #fff;
}

.BookingModalArea .Title {
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  font-family: "GeneralSans-Bold";
  text-align: center;
  margin: 20px 0 25px;
}
.BookingClinicVisit h1 {
  color: rgba(51, 51, 51, 1);
  font-weight: 600;
  font-size: 24px;
  margin: 0 0 30px 0;
  line-height: 30px;
  font-family: "GeneralSans-Semibold";
}
.BookingClinicVisit .CareBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 20px 80px;
  margin: 0 0 20px 0;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 60px 0px rgba(4, 6, 15, 0.05);
  position: relative;
}
.BookingClinicVisit .CareBox .Icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.BookingClinicVisit .CareBox h3 {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: "GeneralSans-Semibold";
  margin: 0 0 5px 0;
}
.BookingClinicVisit .CareBox p {
  color: rgba(100, 149, 237, 1);
  font-size: 14px;
  font-weight: "GeneralSans-Regular";
}
.BookingClinicVisit i {
  color: rgba(85, 85, 85, 1);
}

.TitleBox {
  margin: 0 0 30px 0;
}
.TitleBox h4 {
  margin: 0;
  color: #333333;
  font-size: 20px;
  font-weight: 700;
  font-family: "GeneralSans-Medium";
  letter-spacing: 0.4px;
}
.TitleBox h4.TitleSmall {
  margin: 20px 0 0 0;
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  font-family: "GeneralSans-Medium";
  letter-spacing: 0.4px;
}
.TitleBox h4 a {
  width: 24px;
  display: inline-block;
  text-align: center;
  color: #000;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
}

.CommonFormModal {
  padding: 0 35px;
}
.CommonFormModal .form-group {
  margin: 0 0 17px 0;
  position: relative;
}
.CommonFormModal .form-group .form-control {
  height: auto;
  padding: 12px 17px;
  border: 1px solid #aaa;
  border-radius: 0.25rem;
  font-size: 14px;
  box-shadow: none;
  color: #000;
}
.CommonFormModal .form-group label {
  margin: 0;
  background-color: #fff;
  pointer-events: none;
  transition: all 0.2s;
  padding: 0 9px;
  position: absolute;
  top: 12px;
  left: 10px;
  font-size: 13px;
  font-weight: 500;
}
.CommonFormModal .form-group .form-control:focus + label {
  top: -9px;
}
.CommonFormModal textarea {
  resize: none;
}
.ProfileModalOpen {
  overflow: hidden;
}

.CommonFormModal .ButtonBox button {
  width: 100%;
  border: none;
  outline: 0;
  padding: 15px 0;
  border-radius: 65px;
  margin: 0 0 15px 0;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.CommonFormModal .ButtonBox button:last-child {
  margin: 0;
}
.CommonFormModal .ButtonBox button.Dark {
  background-color: #6495ed;
  color: #fff;
}

.SelectTimeBox {
  padding: 20px 20px 10px;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
  position: relative;
}
.SelectTimeBox aside {
  position: relative;
  padding: 0 0 0 63px;
  margin: 0 0 20px 0;
}
.SelectTimeBox p {
  color: rgba(85, 85, 85, 1);
  margin: 0 0 15px 0;
  display: inline-flex;
  align-items: center;
}
.SelectTimeBox p span {
  margin: 0 10px 0 0;
}
.SelectTimeBox aside .Profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 0;
}
.SelectTimeBox aside h3 {
  color: rgba(51, 51, 51, 1);
  font-family: "GeneralSans-Semibold";
  font-weight: 600;
  margin: 0 0 5px 0;
  font-size: 18px;
}
.SelectTimeBox aside ul {
  display: flex;
}
.SelectTimeBox aside ul li {
  margin: 0 20px 0 0;
  line-height: 10px;
}
.SelectTimeBox aside ul li:first-child {
  color: rgba(85, 85, 85, 1);
}
.SelectTimeBox aside ul li:nth-child(2) {
  color: rgba(100, 149, 237, 1);
}
.SelectTimeBox aside ul li span {
  display: inline-block;
  margin: 0 5px 0 0;
}

.SelectTimeBox {
  margin: 0 0 20px 0;
}
.SelectTimeBox .SelectTimeBody h4 {
  color: rgba(51, 51, 51, 1);
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 15px 0;
  font-family: "GeneralSans-Semibold";
}
.SelectTimeBox .SelectTimeBody ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.SelectTimeBox .SelectTimeBody ul li {
  width: calc(100% / 3);
  position: relative;
  padding: 0 5px;
}
.SelectTimeBox .SelectTimeBody ul li.AlreadyBooked aside span {
  color: rgba(170, 170, 170, 1);
}
.SelectTimeBox .SelectTimeBody ul li.NotAvailable aside span {
  color: rgba(51, 51, 51, 1);
}
.SelectTimeBox .SelectTimeBody ul li.Available aside span {
  color: rgba(100, 149, 237, 1);
}
.SelectTimeBox .SelectTimeBody ul li.AlreadyBooked aside {
  border: 1px solid rgba(170, 170, 170, 1);
}
.SelectTimeBox .SelectTimeBody ul li.NotAvailable aside {
  border: 1px solid rgba(51, 51, 51, 1);
}
.SelectTimeBox .SelectTimeBody ul li.Available aside {
  border: 1px solid rgba(100, 149, 237, 1);
}
.SelectTimeBox .SelectTimeBody ul li aside {
  padding: 0 5px;
  text-align: center;
  padding: 7px;
  border-radius: 24px;
}
.SelectTimeBox .SelectTimeBody ul li input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
}
.SelectTimeBox .SelectTimeBody ul li aside span {
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.SelectTimeBox aside .RightArrow {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.AppointmentPersonBox aside {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 0 20px 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
  padding: 25px 20px 25px 85px;
}
.AppointmentPersonBox aside .Profile {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.AppointmentPersonBox aside .RightArrow {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.AppointmentPersonBox aside h3 {
  color: rgba(51, 51, 51, 1);
  font-family: "GeneralSans-Semibold";
  font-size: 18px;
  margin: 0;
}
.AppointmentPersonBox aside ul li {
  display: inline-block;
  font-weight: 400;
  margin: 0 15px 0 0;
  color: #555;
  position: relative;
  font-size: 14px;
  font-family: "GeneralSans-Regular";
}
.AppointmentPersonBox aside ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 9px;
}
.AddPatientBtn {
  border-radius: 30px;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  display: inline-block;
  border: 1px dashed rgba(100, 149, 237, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 9px 20px;
  color: rgba(100, 149, 237, 1);
  font-weight: 600;
}
.AddPatientBtn:hover {
  color: rgba(100, 149, 237, 1);
}
.AddPatientBtn i {
  display: inline-block;
  margin: 0 5px 0 0;
}

.ReasonBox {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 0 20px 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
  padding: 20px;
}
.ReasonBox h3 {
  color: rgba(51, 51, 51, 1);
  font-family: "GeneralSans-Semibold";
  font-weight: 600;
  margin: 0 0 5px 0;
  font-size: 18px;
}
.ReasonBox p {
  display: inline-block;
  font-weight: 400;
  margin: 0 15px 0 0;
  color: #555;
  position: relative;
  font-size: 14px;
  font-family: "GeneralSans-Regular";
}
.BoxHeading {
  color: rgba(51, 51, 51, 1);
  font-family: "GeneralSans-Semibold";
  font-weight: 600;
  margin: 0 0 10px 0;
  font-size: 18px;
}

.ModalTable {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 0 20px 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
  padding: 10px 25px;
}
.ModalTable table tbody tr td,
.ModalTable table tfoot tr td {
  font-weight: 400;
  color: #555;
  font-size: 14px;
  font-family: "GeneralSans-Regular";
  padding: 10px 0;
}

.ModalTable table tfoot tr td {
  border-top: 1px solid rgba(212, 212, 212, 1);
}
.ModalTable table tbody tr td:last-child,
.ModalTable table tfoot tr td:last-child {
  font-weight: 600;
  color: #000;
  text-align: right;
}

.EndScheduling {
  text-align: center;
  position: relative;
}
.EndScheduling::before {
  content: "";
  position: absolute;
  left: -30px;
  top: -30px;
  right: -30px;
  bottom: -30px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.EndScheduling h2 {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  z-index: 1;
  position: sticky;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.EndScheduling p {
  font-size: 14px;
  line-height: 23px;
  color: #333333;
  margin: 0 0 25px 0;
  z-index: 1;
  position: sticky;
}
.EndScheduling .ButtonBox {
  position: sticky;
  z-index: 99;
}
.UntilVisit p {
  background-color: #e8f0ff;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 15px 10px 15px 20px;
  border-radius: 24px;
  margin: 0 0 25px 0;
  color: rgba(100, 149, 237, 1);
}

.PayMonthly {
  background-color: rgba(252, 46, 90, 1);
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
  padding: 20px;
}
.PayMonthly aside {
  padding: 0 0 0px 80px;
  position: relative;
}
.PayMonthly aside span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.PayMonthly aside h4 {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
  font-size: 18px;
  margin: 0 0 7px 0;
}
.PayMonthly aside h6 {
  font-weight: 400;
  color: 15px;
  color: rgba(245, 245, 245, 1);
  letter-spacing: 0.3px;
}
.PayMonthly p {
  font-weight: 400;
  color: 16px;
  color: rgba(245, 245, 245, 1);
  font-family: "GeneralSans-Light";
  letter-spacing: 0.4px;
  margin: 15px 0 0 0;
  border-top: 1px solid rgba(158, 158, 158, 1);
  padding: 15px 0 0 0;
}

.MembershipPlan {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 20px 10px;
  margin: 20px 0;
}
.MembershipPlan ul {
  display: flex;
  flex-wrap: wrap;
}
.MembershipPlan ul li {
  width: calc(100% / 2 - 20px);
  margin: 0px 10px 10px;
  position: relative;
}
.MembershipPlan ul li aside {
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
  text-align: center;
  position: relative;
}
.MembershipPlan ul li aside .CheckOverlay {
  display: inline-block;
  text-align: center;
  line-height: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(252, 46, 90, 1);
  color: #fff;
  position: absolute;
  top: 20px;
  right: 10px;
  opacity: 0;
}
.MembershipPlan ul li input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
}
.MembershipPlan ul li input:checked ~ aside {
  border-color: rgba(252, 46, 90, 1);
}
.MembershipPlan ul li input:checked ~ aside .CheckOverlay {
  opacity: 1;
}
.MembershipPlan ul li span.Icon {
  background-color: rgba(100, 149, 237, 1);
  border-radius: 12px;
  width: 55px;
  height: 55px;
  display: inline-block;
  margin: 0 0 10px 0;
}
.MembershipPlan ul li h6 {
  color: rgba(51, 51, 51, 1);
  font-weight: 700;
  font-size: 15px;
  font-family: "GeneralSans-Bold";
  margin: 0 0 5px 0;
}
.MembershipPlan ul li p {
  color: rgba(51, 51, 51, 1);
}
.MembershipPlan ul li input:checked .PurchasePlanBtn {
  display: block;
}

.PurchasePlanBtn {
  display: none;
}
.ShareThoughts {
  max-height: 400px;
  overflow: scroll;
  margin: 0 0 20px 0;
}
.ShareThoughts ul {
  display: flex;
  flex-wrap: wrap;
}
.ShareThoughts ul li {
  width: calc(100% / 3 - 10px);
  position: relative;
  margin: 0px 5px 5px;
}
.ShareThoughts ul li input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
}
.ShareThoughts ul li aside {
  background-color: #f5f5f5;
  border-radius: 32px;
  border: 3px solid #f5f5f5;
  padding: 10px;
  width: 100%;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ShareThoughts ul li input:checked ~ aside {
  border-color: #fc2e5a;
}

.PaymentSuccessTable {
  background-color: rgba(232, 240, 255, 1);
  padding: 15px 0 10px;
  margin: 20px 0 40px;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
}
.PaymentSuccessTable tbody tr td {
  padding: 0 10px 10px;
}
.PaymentSuccessTable tbody tr td:nth-child(3) {
  text-align: right;
}
.PaymentSuccessTable tbody tr:last-child td {
  text-align: left;
}
.PaymentSuccessTable tbody tr td p {
  color: #979797;
  font-size: 14px;
  font-weight: 300;
  font-family: "GeneralSans-Light";
}
.PaymentSuccessTable tbody tr td h6 {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  font-family: "GeneralSans-Medium";
}
.Note {
  color: #000;
  font-size: 14px;
  margin: 0 0 50px 0;
}
.Note span {
  font-weight: 600;
  text-align-last: center;
}

.ModalStar {
  display: flex;
  justify-content: center;
  margin: 5px 0 10px 0;
}
.ModalStar li {
  margin: 0 10px 0 0;
  color: #e68c00;
  font-size: 24px;
}
.TypeReview {
  font-size: 16px !important;
  margin: 10px 0 0 0;
  color: #d4d4d4 !important;
}

.ChatBox {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 17px;
  box-shadow: 0 4px 10px 0 #04060f0d;
  cursor: pointer;
}
.ChatBox aside {
  padding: 0 0 0 60px;
}
.ChatBox aside .Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #05825d;
}
.ChatBox aside h6 {
  color: #333333;
  font-size: 15px;
  font-family: "GeneralSans-Semibold";
}
.ChatBox aside p {
  font-weight: 500;
  color: #555555;
  position: relative;
  font-size: 14px;
}
.ChatBox aside .Count {
  width: 30px;
  height: 30px;
  background-color: #fc2e5a;
  text-align: center;
  line-height: 30px;
  color: #fff;
  border-radius: 50px;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  margin: 5px 0 0 0;
}

.SentSmsButton {
  background-color: #6495ed;
  margin: 30px 0 0 0;
  letter-spacing: 0.2px;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  border: none;
  outline: none;
  padding: 15px 0;
}

.AboutUsArea aside {
  margin: 0 0 25px 0;
}
.AboutUsArea aside h4 {
  color: #333;
  font-size: 18px;
  font-family: "GeneralSans-Semibold";
  margin: 0 0 10px 0;
}
.AboutUsArea aside p {
  color: #555;
  font-size: 13px;
  margin: 0 0 15px 0;
}

.ModalBox .ExtraSmallModal .modal-dialog {
  max-width: 359px;
}
.ProfileModalOpen .modal {
  overflow: hidden auto;
}

.StaticArea .card {
  border-radius: 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 24px;
  border: none;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
}
.StaticArea .card + .card {
  margin: 20px 0 0 0;
}
.StaticArea .card .card-header {
  cursor: pointer;
  border-radius: 24px;
  background-color: #ffffff;
  border-radius: 0;
  padding: 15px 55px 15px 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
}
.StaticArea .card .card-header:after {
  content: "\f067";
  font-family: FontAwesome;
  color: rgba(223, 21, 37, 1);
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 19px;
  font-weight: 600;
  line-height: 14px;
  transition: 0.5s all ease-in-out;
}
.StaticArea .card .card-header[aria-expanded="true"]:after {
  content: "\f068";
}
.StaticArea .card .card-header h4 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 0px;
}
.StaticArea .card .card-header h4 span {
  margin: 0 10px 0px 0;
}
.StaticArea .card .card-header a {
  color: #000;
  font-size: 14px;
}
.StaticArea .card .card-body {
  padding: 0 15px 15px 15px;
  border-radius: 24px;
}
.StaticArea .card .card-body:before {
  content: none;
}
.StaticArea .card .card-body p {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: #404040;
}
.StaticArea .card .card-body p + p {
  margin-top: 15px;
}

.EditProfileFigure figure {
  width: 120px;
  margin: 0px auto 25px;
  position: relative;
}
.EditProfileFigure figure span {
  width: 49px;
  height: 49px;
  background-color: #fff;
  border-radius: 5px;
}
.EditProfileFigure figure .InputBox {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  background-color: #fff;
  right: 0;
  line-height: 35px;
  text-align: center;
}
.EditProfileFigure figure .InputBox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.ClinicSelect select {
  background-color: rgba(245, 245, 245, 1);
  border: none;
  color: rgba(85, 85, 85, 1);
  font-size: 14px;
  border-radius: 16px;
  margin: 0 0 20px 0;
  padding: 12px 15px;
  height: auto;
}

.ClinicBox {
  background-color: #ffffff;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 20px;
  border-radius: 24px;
  margin: 0 0 20px 0;
}
.ClinicBox figure {
  display: flex;
}
.ClinicBox figure img {
  width: 78px;
}
.ClinicBox figure figcaption {
  margin: 0 0 5px 10px;
}
.ClinicBox figure figcaption h3 {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.ClinicBox figure figcaption p {
  color: #333333;
  font-size: 14px;
}
.ClinicBox aside {
  display: flex;
  justify-content: space-between;
}
.ClinicBox aside p {
  color: #555555;
}
.ClinicBox aside p span {
  margin: 0 10px 0 0;
}
.ClinicBox aside {
  display: flex;
  align-items: center;
}
.ClinicBox aside button {
  background-color: #6495ed;
  color: #fff;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
  padding: 5px 10px;
  border-radius: 8px;
  outline: none;
  border: none;
}

.HelpCenterBox {
  background-color: #fff;
  box-shadow: 0 4px 60px 0px rgba(4, 6, 15, 0.05);
  border-radius: 20px;
  padding: 20px;
  display: inline-block;
}
.HelpCenterBox h4 {
  color: rgba(51, 51, 51, 1);
  font-weight: 700;
  font-family: "GeneralSans-bold";
  margin: 0 0 15px 0;
}
.HelpCenterBox ul {
  display: flex;
}
.HelpCenterBox ul li {
  margin: 0 20px 0 0;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 20px;
  padding: 20px 30px;
}
.HelpCenterBox ul li:last-child {
  margin-right: 0px;
}
.HelpCenterBox ul li span {
  background-color: rgba(100, 149, 237, 1);
  margin: 0 0 10px 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  display: inline-block;
  border-radius: 12px;
  text-align: center;
  line-height: 56px;
  width: 56px;
  height: 56px;
}
.HelpCenterBox ul li p {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-weight: 500;
  font-family: "GeneralSans-Medium";
}
.HelpCenterBox ul li h6 {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-weight: 700;
  font-family: "GeneralSans-bold";
}

/* .LoginBox .LoginBody .form-group input:focus + label,
.LoginBox .LoginBody .form-group input:not(:placeholder-shown) + label {
  top: -6px;
  font-size: 12px;
  color: #007bff;
  background-color: #ffffff;
  padding: 0 4px;
  border-radius: 4px;
  left: 8px;
} */

.LoginBox .LoginBody .form-group .form-control:focus + label,
.LoginBox .LoginBody .form-group input:not(:placeholder-shown) + label {
  top: -9px !important;
}

.DatePicker_button__-YYqj {
  position: relative;
  left: -35px;
}

header .navbar-nav {
  display: flex;
}

@media (min-width: 992px) {
  header .navbar-nav {
    display: flex;
    align-items: center;
  }
}

.CardCenterCss {
  transform: scale(1.1);
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.2);
  border: 1px solid #6495ed;
}
