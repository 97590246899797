@font-face {
  font-family: "GeneralSans-Bold";
  src: url("../assets/fonts/GeneralSans-Bold.otf");
}
@font-face {
  font-family: "GeneralSans-Semibold";
  src: url("../assets/fonts/GeneralSans-Semibold.otf");
}
@font-face {
  font-family: "GeneralSans-Regular";
  src: url("../assets/fonts/GeneralSans-Regular.otf");
}
@font-face {
  font-family: "GeneralSans-Medium";
  src: url("../assets/fonts/GeneralSans-Medium.otf");
}

@font-face {
  font-family: "GeneralSans-Light";
  src: url("../assets/fonts/GeneralSans-Light.otf");
}

.SideNavBox {
  position: fixed;
  width: 225px;
  background-color: #fff;
  height: 100vh;
  border-right: 1px solid #ddd;
}
.SideNavBox .SideNavHead {
  padding: 20px 15px;
}
.SideNavBox .SideNavHead a {
  display: flex;
  align-items: center;
}
.SideNavBox .SideNavHead a .Icon {
  width: 30px;
}
.SideNavBox .SideNavHead a label {
  margin: 0 0 0 10px;
  color: #000;
  font-weight: 600;
}
.SideNavBox .SideNavBody {
  padding: 15px 15px;
}
.SideNavBox .SideNavBody ul li {
  margin: 0 0 5px 0;
}
.SideNavBox .SideNavBody ul li a {
  display: flex;
  align-items: center;
  color: #1c1c1c;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  position: relative;
}
.SideNavBox .SideNavBody ul li a:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 20px;
  border-radius: 15px;
  left: -2px;
}
.SideNavBox .SideNavBody ul li a .Icon {
  width: 22px;
  margin: 0 10px 0 0;
}
.SideNavBox .SideNavBody ul li.active a {
  background-color: #e2ebf6;
  font-weight: 600;
  color: #000;
}
.SideNavBox .SideNavBody ul li.active a:before {
  background-color: #6495ed;
}
.SideNavBox .SideNavFooter {
  position: absolute;
  bottom: 0;
  padding: 20px;
}
.SideNavBox .SideNavFooter img {
  width: 120px;
}

.DoctorNotification {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 10;
}
.DoctorNotification a {
  width: 30px;
  display: block;
}

.DoctorWrapperArea {
  background-color: #f5f6f6;
  min-height: 100vh;
  padding: 60px 0 0 225px;
}
.DoctorWrapperArea .DoctorWrapper {
  padding: 20px;
}

.TitleBox {
  background-color: #fff;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  position: fixed;
  width: calc(100% - 225px);
  top: 0;
  left: 225px;
  height: 60px;
  z-index: 9;
}
.TitleBox h4 {
  margin: 0;
  color: #838383;
  font-size: 15px;
  font-weight: 500;
}
.TitleBox h4 a {
  width: 20px;
  display: inline-block;
  text-align: center;
  color: #000;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
}

.Title {
  margin: 0 0 20px 0;
}
.Title h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.DashboardBox ul {
  display: flex;
  margin: 0 -10px;
}
.DashboardBox ul li {
  width: 100%;
  padding: 0 10px;
  margin: 0 0 20px 0;
}
.DashboardBox ul li aside {
  padding: 30px 20px;
  border-radius: 10px;
}
.DashboardBox ul li aside p {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin: 0 0 15px 0;
}
.DashboardBox ul li aside h6 {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  color: #000;
}
.DashboardBox ul li:nth-child(1) aside {
  background-color: #e3f5ff;
}
.DashboardBox ul li:nth-child(2) aside {
  background-color: #e5ecf6;
}
.DashboardBox ul li:nth-child(3) aside {
  background-color: #e3f5ff;
}
.DashboardBox ul li:nth-child(4) aside {
  background-color: #e5ecf6;
}
.DashboardBox ul li:nth-child(5) aside {
  background-color: #e5ecf6;
}

.NotificationsList ul li {
  border-bottom: 1px solid #ddd;
  padding: 0 0 20px 60px;
  margin: 0 0 20px 0;
  position: relative;
}
.NotificationsList ul li:last-child {
  border: none;
  padding-bottom: 0;
  margin: 0;
}
.NotificationsList ul li .Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
}
.NotificationsList ul li .Time {
  float: right;
  font-weight: 500;
  color: #000;
  font-size: 13px;
}
.NotificationsList ul li h5 {
  font-size: 14px;
  color: #000;
  margin: 0 0 5px 0;
  font-weight: 600;
}
.NotificationsList ul li p {
  font-size: 13px;
  font-weight: 500;
  color: #565656;
}

.CommonTabs {
  margin: 0 0 20px 0;
}
.CommonTabs .nav-tabs {
  border: none;
  display: inline-flex;
  background-color: #fff;
  padding: 7px;
  border-radius: 50px;
}
.CommonTabs .nav-tabs .nav-item .nav-link {
  border-radius: 50px;
  padding: 10px 45px;
  border: none;
  font-weight: 500;
  color: #5c5c5c;
}
.CommonTabs .nav-tabs .nav-item .nav-link.active {
  background-color: #6495ed;
  color: #fff;
}

.CommonTabsPills {
  margin: 0 0 20px 0;
}
.CommonTabsPills .nav-pills {
  border: none;
  background-color: #fff;
  padding: 7px;
  border-radius: 50px;
}
.CommonTabsPills .nav-pills .nav-item .nav-link {
  border-radius: 50px;
  padding: 10px 45px;
  border: none;
  font-weight: 500;
  color: #5c5c5c;
}
.CommonTabsPills .nav-pills .nav-item .nav-link.active {
  background-color: #6495ed;
  color: #fff;
  font-family: "GeneralSans-Semibold";
}

.DataNoFound {
  text-align: center;
  padding: 140px 0 0;
}
.DataNoFound img {
  max-width: 100px;
}
.DataNoFound p {
  margin: 10px 0 0 0;
  color: #5c5c5c;
  font-weight: 500;
}

.AppoinmentBox {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}
.AppoinmentBox .AppoinmentHead {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px 0;
  align-items: center;
}
.AppoinmentBox .AppoinmentHead aside h3 {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  margin: 0 0 4px 0;
}
.AppoinmentBox .AppoinmentHead aside ul li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
}
.AppoinmentBox .AppoinmentHead aside ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 9px;
}
.AppoinmentBox .AppoinmentHead button {
  border: none;
  padding: 8px 15px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.AppoinmentBox .AppoinmentHead button span {
  display: inline-block;
  width: 17px;
  margin: 0 9px 0 0;
  filter: brightness(0) invert(1);
}
.AppoinmentBox .AppoinmentHead button.Blue {
  background-color: #6495ed;
}
.AppoinmentBox .AppoinmentHead button.Gray {
  background-color: #abb0bc;
}
.AppoinmentBox .AppoinmentBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px 0;
}
.AppoinmentBox .AppoinmentBody ul li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
}
.AppoinmentBox .AppoinmentBody ul li span {
  width: 20px;
  display: inline-block;
}
.AppoinmentBox .AppoinmentBody ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 9px;
}
.AppoinmentBox .AppoinmentBody h6 {
  margin: 0;
  color: #6495ed;
  font-size: 14px;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.AppoinmentBox .AppoinmentFooter {
  display: flex;
  justify-content: space-between;
}
.AppoinmentBox .AppoinmentFooter p {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: #626262;
}
.AppoinmentBox .AppoinmentFooter p span {
  width: 22px;
  margin: 0 5px 0 0;
}
.AppoinmentBox .AppoinmentFooter h6 {
  margin: 0;
  color: #05825d;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
  font-size: 14px;
}

.VideoArea {
  background-color: #000;
  text-align: center;
}
.VideoArea .VideoScreen {
  background-image: url(../assets/imges/VideoCall.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;
  width: 60%;
  margin: auto;
  cursor: pointer;
}

/* .ChatArea .ChatBody {
  min-height: calc(100vh - 190px);
  max-height: calc(100vh - 190px);
  overflow: auto;
} */
.ChatArea .ChatBody ul li {
  margin: 0 0 20px 0;
}
.ChatArea .ChatBody ul li .OtherMessage {
  max-width: 300px;
}
.ChatArea .ChatBody ul li .OtherMessage.OtherMessage {
  background-color: #e1d5fe;
  padding: 20px;
  border-radius: 0px 10px 10px 10px;
}
.ChatArea .ChatBody ul li .OtherMessage.MyMessageWImg {
  background-color: #e1d5fe;
  padding: 20px;
  border-radius: 10px 0 10px 10px;
}

.ChatArea .ChatBody ul li .OtherMessage h5 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.ChatArea .ChatBody ul li .OtherMessage h5 span {
  color: #555;
  font-weight: 400;
  font-size: 13px;
  margin: 0 0 0 5px;
}
.ChatArea .ChatBody ul li .OtherMessage figure {
  margin: 0 0 10px 0;
}
.ChatArea .ChatBody ul li .OtherMessage p {
  font-size: 13px;
  font-weight: 500;
  color: #545454;
  line-break: anywhere;
}
.ChatArea .ChatBody ul li .MyMessage {
  background-color: #e1d5fe;
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  margin-left: auto;
  max-width: 350px;
  text-align: left;
}
.ChatArea .ChatBody ul li .MyMessage h5 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.ChatArea .ChatBody ul li .MyMessage h5 span {
  color: #555;
  font-weight: 400;
  font-size: 13px;
  margin: 0 0 0 5px;
}
.ChatArea .ChatBody ul li .MyMessage p {
  font-size: 13px;
  font-weight: 500;
}
.ChatArea .ChatFooter {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.ChatArea .ChatFooter aside {
  background-color: #f5f5f5;
  display: flex;
  padding: 10px 20px;
  border-radius: 50px;
  width: calc(100% - 60px);
}
.ChatArea .ChatFooter aside span {
  position: relative;
  width: 30px;
  height: 30px;
}
.ChatArea .ChatFooter aside span input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ChatArea .ChatFooter aside input {
  background-color: #fff0;
  border: none;
  box-shadow: none;
  font-size: 14px;
  padding: 0;
  height: auto;
  width: calc(100% - 30px);
  border-left: 1px solid #ddd;
  border-radius: 0;
  margin: 0 0 0 10px;
  padding: 0 0 0 15px;
}
.ChatArea .ChatFooter button {
  width: 50px;
  height: 50px;
  padding: 6px;
  border: none;
  background-color: #fff0;
  outline: 0;
}
.ChatArea .ChatClose {
  background-color: #ffe5e6;
  padding: 20px;
  text-align: center;
}
.ChatArea .ChatClose p {
  color: #ff2532;
  font-weight: 500;
}

.SlotDay {
  margin: 0 0 40px 0;
}
.SlotDay ul {
  display: flex;
  justify-content: space-between;
}
.SlotDay ul li span {
  width: 50px;
  height: 50px;
  display: block;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  color: #000;
  border-radius: 50%;
  border: 2px solid #f5f6f6;
  cursor: pointer;
}
.SlotDay ul li span.active {
  border-color: #6495ed;
  background-color: #fff;
}

.SlotsBox h4 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.SlotsBox ul li {
  display: inline-block;
  margin: 0 3px 10px 0;
}
.SlotsBox ul li span {
  border: 1px solid #000;
  color: #000;
  padding: 7px 7px;
  display: block;
  border-radius: 45px;
  min-width: 125px;
  text-align: center;
  font-weight: 600;
}

.MessagesList > ul > li {
  background-color: #fff;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 17px;
  box-shadow: 0 4px 10px 0 #04060f0d;
  cursor: pointer;
}
.MessagesList ul li aside {
  margin: 0 0 10px 0;
  padding: 0 35px 0 60px;
}
.MessagesList ul li aside .Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.MessagesList ul li aside .Count {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: #fc2e5a;
  text-align: center;
  line-height: 30px;
  color: #fff;
  border-radius: 50px;
  font-weight: 600;
  font-size: 13px;
}
.MessagesList ul li aside h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px 0;
}
.MessagesList ul li aside ul li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
}
.MessagesList ul li aside ul li span {
  width: 20px;
  display: inline-block;
}
.MessagesList ul li aside ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 9px;
}
.MessagesList ul li p {
  font-size: 13px;
  font-weight: 500;
}

.SettingsList ul li {
  margin: 0 0 20px 0;
}
.SettingsList ul li a {
  position: relative;
  background-color: #fff;
  display: block;
  padding: 17px;
  border-radius: 11px;
}
.SettingsList ul li a:after {
  content: "\f105";
  font-family: FontAwesome;
  color: #000;
  position: absolute;
  top: 21px;
  right: 20px;
  font-size: 15px;
  font-weight: 600;
}
.SettingsList ul li a h4 {
  color: #000;
  font-size: 15px;
  margin: 0 0 5px 0;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.SettingsList ul li a p {
  color: #817f7f;
  font-size: 13px;
  font-weight: 500;
}

.PasswordBox .form-group {
  margin: 0 0 25px 0;
  position: relative;
}
.PasswordBox .form-group label {
  margin: 0;
  background-color: #fff;
  padding: 0 9px;
  position: absolute;
  top: -12px;
  left: 10px;
  font-size: 13px;
  font-weight: 500;
}
.PasswordBox .form-group .form-control {
  border: 1px solid #aaa;
  height: auto;
  padding: 15px 20px;
  font-size: 14px;
  box-shadow: none;
  color: #000;
}
.PasswordBox .form-group .Icon {
  position: absolute;
  top: 15px;
  width: 20px;
  right: 10px;
}
.PasswordBox button {
  width: 100%;
  border: none;
  outline: 0;
  padding: 15px 0;
  border-radius: 65px;
  font-weight: 600;
  background-color: #6495ed;
  color: #fff;
}

.StaticBox h4 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.StaticBox p {
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.AvatarBox {
  display: flex;
  align-items: center;
  margin: -10px 0 0 0;
}
.AvatarBox a {
  width: 30px;
  height: 30px;
  color: #000;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  font-size: 21px;
}
.AvatarBox aside {
  padding: 0 0 0 50px;
  position: relative;
  margin: 0 0 0 10px;
}
.AvatarBox aside .Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #fc2e5a;
  border-radius: 10px;
  padding: 3px;
}
.AvatarBox aside h4 {
  font-size: 14px;
  color: #000;
}
.AvatarBox aside ul li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
  font-size: 12px;
}
.AvatarBox aside ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 6px;
}

.ProfileHead {
  margin: 0 0 20px 0;
  display: flex;
}
.ProfileHead figure {
  margin: 0;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}
.ProfileHead figcaption {
  padding: 0 0 0 20px;
}
.ProfileHead figcaption h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0 0 5px 0;
}
.ProfileHead figcaption p {
  font-size: 14px;
  margin: 0 0 10px 0;
  font-weight: 500;
}
.ProfileHead figcaption a {
  background-color: #6495ed;
  color: #fff;
  padding: 10px 25px;
  display: inline-block;
  border-radius: 30px;
  font-weight: 500;
}
.ProfileBody {
  background: #e8f0ff;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  margin: 0 0 30px 0;
}
.ProfileBody aside {
  width: 100%;
  color: #000;
}
.ProfileBody aside p {
  font-size: 14px;
  margin: 0 0 10px 0;
  color: #898a8d;
  font-family: "GeneralSans-Light";
}
.ProfileBody aside h6 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  font-family: "GeneralSans-Medium";
}
.ProfileFooter {
  background-color: #fc2e5a;
  padding: 20px;
  border-radius: 15px;
  display: flex;
}
.ProfileFooter aside {
  width: 100%;
  color: #fff;
}
.ProfileFooter aside p {
  font-size: 13px;
  margin: 0 0 10px 0;
}
.ProfileFooter aside h6 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.AboutBox {
  background-color: #fc2e5a;
  padding: 20px 200px 20px 20px;
  border-radius: 15px;
}
.AboutBox h6 {
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 15px;
  margin: 0 0 5px 0;
  font-family: "GeneralSans-Light";
}
.AboutBox p {
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 15px;
  font-family: "GeneralSans-Medium";
}

/* Modal CSS */

.CallEnd {
  text-align: center;
}
.CallEnd figure img {
  max-height: 90px;
}
.CallEnd h3 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.CallEnd p {
  font-weight: 400;
  margin: 0 0 15px 0;
}
.CallEnd ul {
  display: flex;
  justify-content: center;
}
.CallEnd ul li {
  width: 100%;
  margin: 0 5px;
}
.CallEnd ul li button {
  width: 100%;
  border: none;
  padding: 15px 0;
  border-radius: 50px;
  font-weight: 500;
  outline: 0;
}
.CallEnd ul li button.Light {
  background-color: #e8f0ff;
  color: cornflowerblue;
}
.CallEnd ul li button.Dark {
  background-color: #6495ed;
  color: #fff;
}
.CallEnd article {
  background-color: #e8f0ff;
  padding: 25px;
  border-radius: 20px;
  margin: 0 0 20px 0;
}
.CallEnd article aside {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}
.CallEnd article aside:last-child {
  margin: 0;
}
.CallEnd article aside p {
  margin: 0;
  text-align: left;
  color: #979797;
  line-height: 25px;
  font-size: 14px;
}
.CallEnd article aside p strong {
  display: block;
  color: #000;
  font-weight: 600;
}
.CallEnd article aside p:last-child {
  text-align: right;
}

/* filter css  */

.Filter {
  display: flex;
  justify-content: space-between;
}
.Filter .form-group {
  margin: 0 10px 20px 0;
  display: inline-block;
}
.Filter .form-group:last-child {
  margin: 0 0 20px 0;
}
.Filter .form-group label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
.Filter .form-group .form-control {
  height: 42px;
  line-height: 25px;
  padding: 8px 10px;
  border: 1px solid #cbcaca;
  font-size: 14px;
  margin: 0 10px 0 0;
  box-shadow: none;
  color: #3e3d3d;
  font-weight: 600;
  border-radius: 5px;
}
.AddNewMemberBtn {
  background-color: #6495ed;
  color: #fff;
  border-radius: 50px;
  padding: 13px 50px;
  border: none;
  font-weight: 500;
  display: inline-block;
}
.AddNewMemberBtn:hover {
  color: #fff;
}

.MyVitalsArea ul {
  display: flex;
  justify-content: space-between;
}
.MyVitalsArea ul li {
  border-radius: 24px;
  margin: 0 25px 0 0;
  padding: 10px 40px 10px 10px;
  width: 20%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.MyVitalsArea ul li h6 {
  color: rgba(51, 51, 51, 1);
  font-family: "GeneralSans-bold";
  font-size: 15px;
  margin-bottom: 5px;
}
.MyVitalsArea ul li p {
  color: rgba(85, 85, 85, 1);
  font-size: 13px;
}
.MyVitalsArea ul li:first-child {
  background-color: rgba(255, 125, 234, 0.2);
}
.MyVitalsArea ul li:nth-child(2) {
  background-color: rgba(19, 14, 255, 0.2);
}
.MyVitalsArea ul li:nth-child(3) {
  background-color: rgba(0, 255, 133, 0.2);
}
.MyVitalsArea ul li:nth-child(4) {
  background-color: rgba(255, 0, 0, 0.2);
}
.MyVitalsArea ul li:nth-child(5) {
  background-color: rgba(255, 184, 0, 0.2);
}
.MyVitalsArea ul li:nth-child(6) {
  background-color: rgba(0, 255, 255, 0.2);
}

.LabReporsArea article {
  background-color: #fff;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  margin: 0 0 20px 0;
  border-radius: 24px;
  padding: 15px;
}
.LabReporsArea article aside {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.LabReporsArea article aside:first-child {
  margin: 0 0 15px 0;
}
.LabReporsArea article aside h4 {
  font-size: 15px;
  font-family: "GeneralSans-Semibold";
  color: #333;
}
.LabReporsArea article aside p {
  color: #555;
  font-size: 13px;
}
.LabReporsArea article aside p span {
  display: inline-block;
  width: 22px;
  margin: 0 10px 0 0;
}
.LabReporsArea article aside h6 {
  font-size: 13px;
  font-family: "GeneralSans-Semibold";
  color: #555;
}
.LabReporsArea article aside a {
  color: #6495ed;
  text-decoration: underline;
}

.MedicalImagesArea ul {
  display: flex;
  flex-wrap: wrap;
}
.MedicalImagesArea ul li {
  width: calc(100% / 6 - 20px);
  padding: 10px;
  margin: 0 20px 30px 0;
  background-color: #fff;
}
.MedicalImagesArea ul li figure {
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 10px;
}
.MedicalImagesArea ul li figcaption h6 {
  font-size: 14px;
  font-family: "GeneralSans-bold";
  color: #333;
  margin-bottom: 5px;
}
.MedicalImagesArea ul li figcaption p {
  font-size: 12px;
  color: #555;
}

.DoctorNotification2 {
  position: fixed;
  right: 50px;
  top: 8px;
  z-index: 10;
}
.EndChatButton {
  background-color: rgba(236, 84, 84, 1);
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 13px;
  border-radius: 10px;
  font-family: "GeneralSans-Semibold";
}
.EndChatButton span {
  display: inline-block;
  margin: 0 5px 0 0;
}
.EndChatButton:hover {
  color: #fff;
}
