@font-face {
  font-family: "GeneralSans-Bold";
  src: url("./fonts/GeneralSans-Bold.otf");
}
@font-face {
  font-family: "GeneralSans-Semibold";
  src: url("./fonts/GeneralSans-Semibold.otf");
}
@font-face {
  font-family: "GeneralSans-Regular";
  src: url("./fonts/GeneralSans-Regular.otf");
}
@font-face {
  font-family: "GeneralSans-Medium";
  src: url("./fonts/GeneralSans-Medium.otf");
}

@font-face {
  font-family: "GeneralSans-Light";
  src: url("./fonts/GeneralSans-Light.otf");
}

.switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 2px 0 3px 50px;
  font-weight: 400;
  font-size: 12px;
  color: #646467;
  float: right;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 21px;
  border-radius: 50px;
  width: 40px;
  border: 1px solid #3fdf3d;
  min-width: auto;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 0;
  top: 0;
  background-color: #3fdf3d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  margin: auto;
}
.switch input:checked + .slider {
  background-color: #3fdf3d;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  transform: translateX(19px);
  background: #fff;
}

.SideNavBox {
  position: fixed;
  width: 225px;
  background-color: #fff;
  height: 100vh;
  border-right: 1px solid #ddd;
}
.SideNavBox .SideNavHead {
  padding: 20px 15px;
}
.SideNavBox .SideNavHead a {
  display: flex;
  align-items: center;
}
.SideNavBox .SideNavHead a .Icon {
  width: 30px;
}
.SideNavBox .SideNavHead a label {
  margin: 0 0 0 10px;
  color: #000;
  font-weight: 600;
}
.SideNavBox .SideNavBody {
  padding: 15px 15px;
}
.SideNavBox .SideNavBody ul li {
  margin: 0 0 5px 0;
}
.SideNavBox .SideNavBody ul li a {
  display: flex;
  align-items: center;
  color: #1c1c1c;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  position: relative;
}
.SideNavBox .SideNavBody ul li a:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 20px;
  border-radius: 15px;
  left: -2px;
}
.SideNavBox .SideNavBody ul li a .Icon {
  width: 22px;
  margin: 0 10px 0 0;
}
.SideNavBox .SideNavBody ul li.active a {
  background-color: #e2ebf6;
  font-weight: 600;
  color: #000;
}
.SideNavBox .SideNavBody ul li.active a:before {
  background-color: #6495ed;
}
.SideNavBox .SideNavFooter {
  position: absolute;
  bottom: 0;
  padding: 20px;
}
.SideNavBox .SideNavFooter img {
  width: 120px;
}

.DoctorNotification {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 10;
}
.DoctorNotification a {
  width: 30px;
  display: block;
}

.DoctorWrapperArea {
  background-color: #f5f6f6;
  min-height: 100vh;
  padding: 60px 0 0 225px;
}
.DoctorWrapperArea .DoctorWrapper {
  padding: 20px;
}

.Title {
  margin: 0 0 20px 0;
}
.Title h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.DashboardBox ul {
  display: flex;
  margin: 0 -10px;
}
.DashboardBox ul li {
  width: 100%;
  padding: 0 10px;
  margin: 0 0 20px 0;
}
.DashboardBox ul li aside {
  padding: 30px 20px;
  border-radius: 10px;
}
.DashboardBox ul li aside p {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin: 0 0 15px 0;
}
.DashboardBox ul li aside h6 {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  color: #000;
}
.DashboardBox ul li:nth-child(1) aside {
  background-color: #e3f5ff;
}
.DashboardBox ul li:nth-child(2) aside {
  background-color: #e5ecf6;
}
.DashboardBox ul li:nth-child(3) aside {
  background-color: #e3f5ff;
}
.DashboardBox ul li:nth-child(4) aside {
  background-color: #e5ecf6;
}
.DashboardBox ul li:nth-child(5) aside {
  background-color: #e5ecf6;
}

.NotificationsList ul li {
  border-bottom: 1px solid #ddd;
  padding: 0 0 20px 60px;
  margin: 0 0 20px 0;
  position: relative;
}
.NotificationsList ul li:last-child {
  border: none;
  padding-bottom: 0;
  margin: 0;
}
.NotificationsList ul li .Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
}
.NotificationsList ul li .Time {
  float: right;
  font-weight: 500;
  color: #000;
  font-size: 13px;
}
.NotificationsList ul li h5 {
  font-size: 14px;
  color: #000;
  margin: 0 0 5px 0;
  font-weight: 600;
}
.NotificationsList ul li p {
  font-size: 13px;
  font-weight: 500;
  color: #565656;
}

.CommonTabs {
  margin: 0 0 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
}
.CommonTabs .nav-tabs {
  border: none;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  display: inline-flex;
  background-color: #fff;
  padding: 7px;
  border-radius: 50px;
}
.CommonTabs .nav-tabs .nav-item .nav-link {
  border-radius: 50px;
  padding: 10px 45px;
  border: none;
  font-weight: 500;
  color: #5c5c5c;
}
.CommonTabs .nav-tabs .nav-item .nav-link.active {
  background-color: #6495ed;
  color: #fff;
}
.CommonTabs .form-control {
  max-width: 200px;
}

.CommonTabs {
  margin: 0 0 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
}
.CommonTabs .nav-pills {
  border: none;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  display: inline-flex;
  background-color: #fff;
  padding: 7px;
  border-radius: 50px;
}
.CommonTabs .nav-pills .nav-item .nav-link {
  border-radius: 50px;
  padding: 10px 45px;
  border: none;
  font-weight: 500;
  color: #5c5c5c;
}
.CommonTabs .nav-pills .nav-item .nav-link.active {
  background-color: #6495ed;
  color: #fff;
}
.CommonTabs .form-control {
  max-width: 200px;
}

.CommonTabsPills {
  margin: 0 0 20px 0;
}
.CommonTabsPills .nav-pills {
  border: none;
  background-color: #fff;
  padding: 7px;
  border-radius: 50px;
}
.CommonTabsPills .nav-pills .nav-item .nav-link {
  border-radius: 50px;
  padding: 10px 45px;
  border: none;
  font-weight: 500;
  color: #5c5c5c;
}
.CommonTabsPills .nav-pills .nav-item .nav-link.active {
  background-color: #6495ed;
  color: #fff;
  font-family: "GeneralSans-Semibold";
}

.CommonTabs .form-control {
  font-size: 14px;
}
.CommonTabs .form-control:focus {
  box-shadow: none;
}

.DataNoFound {
  text-align: center;
  padding: 140px 0 0;
}
.DataNoFound img {
  max-width: 100px;
}
.DataNoFound p {
  margin: 10px 0 0 0;
  color: #5c5c5c;
  font-weight: 500;
}

.AppoinmentBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.AppoinmentBox {
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 20px;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  height: calc(100% - 20px);
  margin: 0 0 20px 0;
}
.AppoinmentBox .AppoinmentHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 7px 0;
}
.AppoinmentBox .AppoinmentHead aside h3 {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  margin: 0 0 4px 0;
}
.AppoinmentBox .AppoinmentHead aside ul li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
}
.AppoinmentBox .AppoinmentHead aside ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 9px;
}
.AppoinmentBox .AppoinmentHead button {
  border: none;
  padding: 8px 15px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.AppoinmentBox .AppoinmentHead button:focus {
  outline: none;
  border: none;
}
.AppoinmentBox .AppoinmentHead button span {
  display: inline-block;
  width: 17px;
  margin: 0 9px 0 0;
  filter: brightness(0) invert(1);
}
.AppoinmentBox .AppoinmentHead button.Blue {
  background-color: #6495ed;
}
.AppoinmentBox .AppoinmentHead button.Gray {
  background-color: #abb0bc;
}
.AppoinmentBox .AppoinmentBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
}
.AppoinmentBox .AppoinmentBody ul {
  display: flex;
  align-items: center;
}
.AppoinmentBox .AppoinmentBody ul li {
  display: inline-flex;
  align-items: center;
  margin: 0 25px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
}
.AppoinmentBox .AppoinmentBody ul li:last-child {
  font-weight: 500;
  color: #6495ed;
}
.AppoinmentBox .AppoinmentBody ul li span {
  width: 16px;
  display: inline-flex;
  line-height: 16px;
  margin: 0 5px 0 0;
}
.AppoinmentBox .AppoinmentBody ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #6495ed;
  border-radius: 50px;
  top: 9px;
}
.AppoinmentBox .AppoinmentBody h6 {
  margin: 0;
  color: #6495ed;
  font-size: 14px;
  font-weight: 500;
}
.AppoinmentBox .AppoinmentFooter {
  display: flex;
  justify-content: space-between;
}
.AppoinmentBox .AppoinmentFooter p {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: #626262;
}
.AppoinmentBox .AppoinmentFooter p span {
  width: 22px;
  margin: 0 5px 0 0;
}
.AppoinmentBox .AppoinmentFooter h6 {
  margin: 0;
  color: #05825d;
  font-weight: 700;
  font-size: 14px;
}
.AppoinmentBox button {
  border: none;
  padding: 8px 15px;
  border-radius: 8px;
  margin: 15px 0 0 0;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.AppoinmentBox button.FullWidth {
  width: 100%;
  justify-content: center;
}
.AppoinmentBox button.Blue {
  background-color: #6495ed;
  color: #fff;
}
.AppoinmentBox button.Red {
  background-color: #ffeae5;
  color: #f23a00;
}
.AppoinmentBox button span {
  display: inline-block;
  width: 17px;
  margin: 0 9px 0 0;
}
.AppoinmentBox button.Red:focus {
  outline: none;
}

.VideoArea {
  background-color: #000;
  text-align: center;
}
.VideoArea .VideoScreen {
  background-image: url(./imges/VideoCall.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;
  width: 60%;
  margin: auto;
  cursor: pointer;
}

.ChatArea .ChatBody {
  min-height: calc(100vh - 190px);
  max-height: calc(100vh - 190px);
  overflow: auto;
}
.ChatArea .ChatBody ul li {
  margin: 0 0 20px 0;
}
.ChatArea .ChatBody ul li .OtherMessage {
  max-width: 300px;
}
.ChatArea .ChatBody ul li .OtherMessage h5 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.ChatArea .ChatBody ul li .OtherMessage h5 span {
  color: #555;
  font-weight: 400;
  font-size: 13px;
  margin: 0 0 0 5px;
}
.ChatArea .ChatBody ul li .OtherMessage figure {
  margin: 0 0 10px 0;
}
.ChatArea .ChatBody ul li .OtherMessage p {
  font-size: 13px;
  font-weight: 500;
  color: #545454;
}
.ChatArea .ChatBody ul li .MyMessage {
  background-color: #e1d5fe;
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  margin-left: auto;
  max-width: 350px;
  text-align: left;
}
.ChatArea .ChatBody ul li .MyMessage h5 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.ChatArea .ChatBody ul li .MyMessage h5 span {
  color: #555;
  font-weight: 400;
  font-size: 13px;
  margin: 0 0 0 5px;
}
.ChatArea .ChatBody ul li .MyMessage p {
  font-size: 13px;
  font-weight: 500;
}
.ChatArea .ChatFooter {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.ChatArea .ChatFooter aside {
  background-color: #f5f5f5;
  display: flex;
  padding: 4px 20px;
  border-radius: 50px;
  width: calc(100% - 60px);
}
.ChatArea .ChatFooter aside span {
  position: relative;
  width: 30px;
  height: 30px;
}
.ChatArea .ChatFooter aside span input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ChatArea .ChatFooter aside input {
  background-color: #fff0;
  border: none;
  box-shadow: none;
  font-size: 14px;
  padding: 0;
  height: auto;
  width: calc(100% - 30px);
  border-left: 1px solid #ddd;
  border-radius: 0;
  margin: 0 0 0 10px;
  padding: 0 0 0 15px;
}
.ChatArea .ChatFooter button {
  width: 50px;
  height: 50px;
  padding: 6px;
  border: none;
  background-color: #fff0;
  outline: 0;
}
.ChatArea .ChatClose {
  background-color: #ffe5e6;
  padding: 20px;
  text-align: center;
}
.ChatArea .ChatClose p {
  color: #ff2532;
  font-weight: 500;
}

.SlotDay {
  margin: 0 0 40px 0;
}
.SlotDay ul {
  display: flex;
  justify-content: space-between;
}
.SlotDay ul li span {
  width: 50px;
  height: 50px;
  display: block;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  color: #000;
  border-radius: 50%;
  border: 2px solid #f5f6f6;
  cursor: pointer;
}
.SlotDay ul li span.active {
  border-color: #6495ed;
  background-color: #fff;
}

.SlotsBox h4 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.SlotsBox ul li {
  display: inline-block;
  margin: 0 3px 10px 0;
}
.SlotsBox ul li span {
  border: 1px solid #000;
  color: #000;
  padding: 7px 7px;
  display: block;
  border-radius: 45px;
  min-width: 125px;
  text-align: center;
  font-weight: 600;
}

.MessagesList > ul > li {
  background-color: #fff;
  margin: 0 0 20px 0;
  padding: 20px;
  border-radius: 17px;
  box-shadow: 0 4px 10px 0 #04060f0d;
  cursor: pointer;
}
.MessagesList ul li aside {
  margin: 0 0 10px 0;
  padding: 0 35px 0 60px;
}
.MessagesList ul li aside .Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.MessagesList ul li aside .Count {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: #fc2e5a;
  text-align: center;
  line-height: 30px;
  color: #fff;
  border-radius: 50px;
  font-weight: 600;
  font-size: 13px;
}
.MessagesList ul li aside h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px 0;
}
.MessagesList ul li aside ul li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
}
.MessagesList ul li aside ul li span {
  width: 20px;
  display: inline-block;
}
.MessagesList ul li aside ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 9px;
}
.MessagesList ul li p {
  font-size: 13px;
  font-weight: 500;
}

.SettingsList ul li {
  margin: 0 0 20px 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
}
.SettingsList ul li a {
  position: relative;
  background-color: #fff;
  display: block;
  padding: 17px;
  border-radius: 11px;
}
.SettingsList ul li a:after {
  content: "\f105";
  font-family: FontAwesome;
  color: #000;
  position: absolute;
  top: 21px;
  right: 20px;
  font-size: 15px;
  font-weight: 600;
}
.SettingsList ul li a h4 {
  color: #000;
  font-size: 15px;
  margin: 0 0 5px 0;
  font-weight: 600;
  font-family: "GeneralSans-Semibold";
}
.SettingsList ul li a p {
  color: #817f7f;
  font-size: 13px;
  font-weight: 500;
}

.PasswordBox .form-group {
  margin: 0 0 25px 0;
  position: relative;
}
.PasswordBox .form-group label {
  margin: 0;
  background-color: #fff;
  padding: 0 9px;
  position: absolute;
  top: -12px;
  left: 10px;
  font-size: 13px;
  font-weight: 500;
}
.PasswordBox .form-group .form-control {
  border: 1px solid #aaa;
  height: auto;
  padding: 15px 20px;
  font-size: 14px;
  box-shadow: none;
  color: #000;
}
.PasswordBox .form-group .Icon {
  position: absolute;
  top: 15px;
  width: 20px;
  right: 10px;
}
.PasswordBox button {
  width: 100%;
  border: none;
  outline: 0;
  padding: 15px 0;
  border-radius: 65px;
  font-weight: 600;
  background-color: #6495ed;
  color: #fff;
}

.StaticBox h4 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.StaticBox p {
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.AvatarBox {
  display: flex;
  align-items: center;
  margin: -10px 0 0 0;
}
.AvatarBox a {
  width: 30px;
  height: 30px;
  color: #000;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  font-size: 21px;
}
.AvatarBox aside {
  padding: 0 0 0 50px;
  position: relative;
  margin: 0 0 0 10px;
}
.AvatarBox aside .Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #fc2e5a;
  border-radius: 10px;
  padding: 3px;
}
.AvatarBox aside h4 {
  font-size: 14px;
  color: #000;
}
.AvatarBox aside ul li {
  display: inline-block;
  margin: 0 15px 0 0;
  font-weight: 500;
  color: #555;
  position: relative;
  font-size: 12px;
}
.AvatarBox aside ul li + li:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 6px;
}

.ProfileHead {
  margin: 0 0 20px 0;
  display: flex;
}
.ProfileHead figure {
  margin: 0;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}
.ProfileHead figcaption {
  padding: 0 0 0 20px;
}
.ProfileHead figcaption h5 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0 0 5px 0;
}
.ProfileHead figcaption p {
  font-size: 14px;
  margin: 0 0 10px 0;
  font-weight: 500;
}
.ProfileHead figcaption a {
  background-color: #6495ed;
  color: #fff;
  padding: 10px 25px;
  display: inline-block;
  border-radius: 30px;
  font-weight: 500;
}
.ProfileBody {
  background: #e8f0ff;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  margin: 0 0 30px 0;
}
.ProfileBody aside {
  width: 100%;
  color: #000;
}
.ProfileBody aside p {
  font-size: 13px;
  margin: 0 0 10px 0;
}
.ProfileBody aside h6 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}
.ProfileFooter {
  background-color: #fc2e5a;
  padding: 20px;
  border-radius: 15px;
  display: flex;
}
.ProfileFooter aside {
  width: 100%;
  color: #fff;
}
.ProfileFooter aside p {
  font-size: 13px;
  margin: 0 0 10px 0;
}
.ProfileFooter aside h6 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

/* Modal CSS */

.CallEnd {
  text-align: center;
}
.CallEnd figure img {
  max-height: 90px;
}
.CallEnd h3 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin: 0 0 10px 0;
}
.CallEnd p {
  font-weight: 400;
  margin: 0 0 15px 0;
}
.CallEnd ul {
  display: flex;
  justify-content: center;
}
.CallEnd ul li {
  width: 100%;
  margin: 0 5px;
}
.CallEnd ul li button {
  width: 100%;
  border: none;
  padding: 15px 0;
  border-radius: 50px;
  font-weight: 500;
  outline: 0;
}
.CallEnd ul li button.Light {
  background-color: #e8f0ff;
  color: cornflowerblue;
}
.CallEnd ul li button.Dark {
  background-color: #6495ed;
  color: #fff;
}
.CallEnd article {
  background-color: #e8f0ff;
  padding: 25px;
  border-radius: 20px;
  margin: 0 0 20px 0;
}
.CallEnd article aside {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}
.CallEnd article aside:last-child {
  margin: 0;
}
.CallEnd article aside p {
  margin: 0;
  text-align: left;
  color: #979797;
  line-height: 25px;
  font-size: 14px;
}
.CallEnd article aside p strong {
  display: block;
  color: #000;
  font-weight: 600;
}
.CallEnd article aside p:last-child {
  text-align: right;
}

.MyProfileBox {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
  padding: 20px 20px 20px 80px;
  border-radius: 17px;
  box-shadow: 0 4px 10px 0 #04060f0d;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.MyProfileBox .Icon {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  transform: translateY(-50%);
}

.MyProfileBox aside h6 {
  color: #333333;
  font-size: 17px;
  font-family: "GeneralSans-Semibold";
  margin: 0 0 5px 0;
}
.MyProfileBox aside p {
  font-weight: 500;
  color: #555555;
  position: relative;
  font-size: 14px;
}
.MyProfileBox aside ul {
  display: flex;
  color: rgba(85, 85, 85, 1);
}
.MyProfileBox aside ul li {
  margin: 0 20px 0 0;
  position: relative;
}
.MyProfileBox aside ul li:first-child:before {
  position: absolute;
  content: "|";
  right: -10px;
}
.MyProfileBox aside ul li:nth-child(2) {
  color: rgba(251, 46, 90, 1);
}
.MyProfileBox aside ul li:nth-child(2)::after {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  right: -12px;
  background-color: #979797;
  border-radius: 50px;
  top: 9px;
}

.MyProfileBody aside {
  border-radius: 24px;
  padding: 30px 50px 30px 20px;
  display: flex;
  justify-content: space-between;
}
.MyProfileBody aside p {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}
.MyProfileBody aside.Blue {
  background-color: rgba(232, 240, 255, 1);
  box-shadow: 0 4px 60px 0 rgba(255, 255, 255, 1);
  margin: 0 0 30px 0;
}
.MyProfileBody aside.Blue p label {
  margin: 0px;
  color: rgba(151, 151, 151, 1);
  font-family: "GeneralSans-Light";
}
.MyProfileBody aside.Blue p span {
  color: rgba(0, 0, 0, 1);
  font-family: "GeneralSans-Medium";
}

.MyProfileBody aside.Red {
  background-color: rgba(252, 46, 90, 1);
}
.MyProfileBody aside.Red p label {
  margin: 0px;
  color: rgba(255, 255, 255, 1);
  font-family: "GeneralSans-Light";
}
.MyProfileBody aside.Red p span {
  color: rgba(255, 255, 255, 1);
  font-family: "GeneralSans-Medium";
}

.AddNewMemberBtn {
  background-color: #6495ed;
  color: #fff;
  border-radius: 50px;
  padding: 13px 25px;
  border: none;
  font-weight: 500;
  display: inline-block;
}
.AddNewMemberBtn:hover {
  color: #fff;
}

.TurnNotificationArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.TurnNotificationArea h6 {
  font-family: "GeneralSans-Semibold";
  font-size: 16px;
}

.MyVitalsArea ul {
  display: flex;
}
.MyVitalsArea ul li {
  border-radius: 24px;
  margin: 0 25px 0 0;
  padding: 10px 40px 10px 10px;
  min-width: 140px;
  max-width: 130px;
  min-height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.MyVitalsArea ul li h6 {
  color: rgba(51, 51, 51, 1);
  font-family: "GeneralSans-bold";
  font-size: 15px;
  margin-bottom: 5px;
}
.MyVitalsArea ul li p {
  color: rgba(85, 85, 85, 1);
  font-size: 13px;
}
.MyVitalsArea ul li:first-child {
  background-color: rgba(255, 125, 234, 0.2);
}
.MyVitalsArea ul li:nth-child(2) {
  background-color: rgba(19, 14, 255, 0.2);
}
.MyVitalsArea ul li:nth-child(3) {
  background-color: rgba(0, 255, 133, 0.2);
}
.MyVitalsArea ul li:nth-child(4) {
  background-color: rgba(255, 0, 0, 0.2);
}
.MyVitalsArea ul li:nth-child(5) {
  background-color: rgba(255, 184, 0, 0.2);
}
.MyVitalsArea ul li:nth-child(6) {
  background-color: rgba(0, 255, 255, 0.2);
}

.VitalsAailable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.VitalsAailable figure {
  margin: 0 0 20px 0;
}
.VitalsAailable figcaption h6 {
  color: rgba(85, 85, 85, 1);
  font-family: "GeneralSans-Medium";
  font-size: 15px;
  text-align: center;
  margin: 0 0 50px 0;
}
.VitalsAailable figcaption p {
  color: #6495ed;
  font-size: 14px;
  border-radius: 24px;
  text-align: center;
  background-color: #e8f0ff;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 10px 20px;
}

.LabReporsArea article {
  background-color: #fff;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  margin: 0 0 20px 0;
  border-radius: 24px;
  padding: 15px;
}
.LabReporsArea article aside {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.LabReporsArea article aside:first-child {
  margin: 0 0 15px 0;
}
.LabReporsArea article aside h4 {
  font-size: 15px;
  font-family: "GeneralSans-Semibold";
  color: #333;
}
.LabReporsArea article aside p {
  color: #555;
  font-size: 13px;
}
.LabReporsArea article aside p span {
  display: inline-block;
  width: 22px;
  margin: 0 10px 0 0;
}
.LabReporsArea article aside h6 {
  font-size: 13px;
  font-family: "GeneralSans-Semibold";
  color: #555;
}
.LabReporsArea article aside a {
  color: #6495ed;
  text-decoration: underline;
}

.MedicalImagesArea ul {
  display: flex;
  flex-wrap: wrap;
}
.MedicalImagesArea ul li {
  width: calc(100% / 7 - 30px);
  margin: 0 30px 30px 0;
}
.MedicalImagesArea ul li figure {
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 10px;
}
.MedicalImagesArea ul li figcaption h6 {
  font-size: 14px;
  font-family: "GeneralSans-bold";
  color: #333;
  margin-bottom: 5px;
}
.MedicalImagesArea ul li figcaption p {
  font-size: 12px;
  color: #555;
}

.CorePlanArea p {
  color: #555;
  font-size: 13px;
  margin: 0 0 20px 0;
}

.InsuranceArea article {
  background-color: #e3fae3;
  margin: 0 0 20px 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 15px 10px;
  text-align: center;
  border-radius: 24px;
  font-family: "GeneralSans-Medium";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.InsuranceArea article.Green {
  background-color: #e3fae3;
  color: #3fdf3d;
}
.InsuranceArea article.Orange {
  background-color: #fff5e9;
  color: #ff9922;
}
.InsuranceArea article span {
  margin: 0 5px 0 0;
  display: inline-block;
}
.InsuranceArea article ~ p {
  color: #555;
  font-size: 13px;
  text-align: center;
  margin: 0 0 25px 0;
}
.InsuranceArea .InsuranceBox {
  max-width: 375px;
  margin: 0px auto 25px;
}
.InsuranceArea .InsuranceBox .CenterBox {
  background-color: #fc2e5a;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 25px;
}
.InsuranceArea .InsuranceBox aside {
  background-color: #fff;
  border-radius: 18px;
  margin: 0 0 15spx 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
}
.InsuranceArea .InsuranceBox aside p {
  color: #333;
  font-size: 14px;
  text-align: center;
  padding: 10px;
}
.InsuranceArea .InsuranceBox aside p span {
  color: #333;
  font-size: 18px;
  font-family: "GeneralSans-Semibold";
}
.InsuranceArea .InsuranceBox table tr td {
  padding: 10px 0 10px 0;
}
.InsuranceArea .InsuranceBox table tr td p {
  font-size: 14px;
  color: #fff;
  margin: 0 0 5px 0;
}
.InsuranceArea .InsuranceBox table tr td h5 {
  font-size: 16px;
  color: #fff;
  font-family: "GeneralSans-Semibold";
}
.InsuranceArea .InsuranceBox table tr td:last-child {
  text-align: right;
}
.InsuranceArea .InsuranceBox table tr:last-child td {
  text-align: left;
}
.InsuranceArea .VerifyBtn {
  font-family: "GeneralSans-Medium";
  display: block;
  text-align: center;
}
.InsuranceArea .VerifyBtn.Green {
  color: #3fdf3d;
}
.InsuranceArea .VerifyBtn.Orange {
  color: #ff9922;
}
.InsuranceArea .VerifyBtn span {
  margin: 0 5px 0 0;
  display: inline-block;
}

.RenewArea {
  width: 35%;
  float: left;
  padding: 0 25px 0 0;
}
.RenewArea span.TopSpan {
  margin: 0 0 20px 0;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  padding: 15px 10px;
  text-align: center;
  border-radius: 24px;
  font-family: "GeneralSans-Medium";
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 229, 230, 1);
  color: rgba(255, 37, 50, 1);
}
.RenewArea span.Red {
  background-color: rgba(255, 229, 230, 1);
  color: rgba(255, 37, 50, 1);
}
.RenewArea span.Green {
  background-color: #e3fae3;
  color: #3fdf3d;
}
.RenewArea span.Green img {
  margin: 0 5px 0 0;
}
.RenewArea .PayMonthlyBox {
  background-color: #fc2e5a;
  border-radius: 24px;
  padding: 20px;
  margin: 0 0 30px 0;
}
.RenewArea .PayMonthlyBox aside {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px 0;
}
.RenewArea .PayMonthlyBox aside figcaption h4 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "GeneralSans-Semibold";
}
.RenewArea .PayMonthlyBox aside figcaption p {
  color: #fff;
  font-size: 16px;
}
.RenewArea .PayMonthlyBox aside button.Expired {
  background-color: #fefefe;
  border-radius: 5px;
  padding: 5px 10px;
  color: #ff2532;
  border: none;
  outline: none;
  font-family: "GeneralSans-Medium";
}
.RenewArea .PayMonthlyBox aside button.Expired:focus {
  outline: none;
}
.RenewArea .PayMonthlyBox .PayMonthlyTable {
  position: relative;
  padding: 0 0 0 70px;
}
.RenewArea .PayMonthlyBox .PayMonthlyTable .Icon {
  position: absolute;
  left: 0;
  top: 0;
}
.RenewArea .PayMonthlyBox .PayMonthlyTable table tbody tr td:last-child {
  text-align: right;
}
.RenewArea .PayMonthlyBox .PayMonthlyTable table tbody tr td h6 {
  color: #f5f5f5;
  font-family: "GeneralSans-Semibold";
  font-size: 14px;
  margin: 0 0 5px 0;
}
.RenewArea .PayMonthlyBox .PayMonthlyTable table tbody tr td p {
  color: #f5f5f5;
  font-size: 14px;
  margin: 0 0 5px 0;
}

.TransactionArea {
  width: 65%;
  float: right;
}
.TransactionArea .Title {
  color: #333;
  font-family: "GeneralSans-bold";
  font-size: 16px;
}
.TransactionArea article {
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
  border-radius: 24px;
  padding: 20px;
  margin: 0 0 20px 0;
}
.TransactionArea article aside {
  display: flex;
  justify-content: space-between;
}
.TransactionArea article aside:first-child {
  margin: 0 0 10px 0;
}
.TransactionArea article aside h5 {
  font-size: 16px;
  color: #333;
  font-family: "GeneralSans-Semibold";
  margin: 0;
}
.TransactionArea article aside h5.Red {
  color: #fc2e5a;
}
.TransactionArea article aside p {
  color: #555;
  font-size: 13px;
}
.TransactionArea article aside h6 {
  color: #555;
  font-family: "GeneralSans-Semibold";
  font-size: 14px;
  margin: 0;
}
.TransactionArea article aside h6 span {
  color: #555;
  font-family: "GeneralSans-Regular";
}
