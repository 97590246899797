.DatePicker_dateLabel__zNdfY {
  font-size: 13px !important;
}
#congModal {
  padding: 4rem !important;
}

.newul li {
  list-style-type: disc !important;
  margin: 15px 0px;
  font-size: 16px;
}

.CheckProfileBox {
  position: relative;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
  display: flex;
  width: 90%;
  border-radius: 10px;
  margin: 10px auto 10px;
}
.CheckProfileBox h3 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-left: 20px;
}
.CheckProfileBox ul {
  margin: 0px 50px;
}
.CheckProfileBox ul li {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-left: 20px;
}
.CheckProfileBox ul li span {
  font-weight: 700;
}
.CheckProfileBox h6 {
  font-weight: 700;
}
.image-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.RightArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
.image-avatar2 {
  width: 40px;

  border-radius: 50%;
}
.typing {
  font-size: 12px;
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 80px;
  left: 18px;
}

.typing strong {
  margin-right: 8px;
}

.typing .dots {
  display: flex;
}

.typing .dots span {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 3px;
  background-color: #000; /* Color of the dots */
  border-radius: 50%;
  animation: bounce 1.4s infinite;
}

.typing .dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing .dots span:nth-child(3) {
  animation-delay: 0.4s;
}

/* .typing2 {
  position: fixed;

  bottom: 94px;
} */

.typing2 {
  font-size: 12px !important;
  position: fixed;
  bottom: 94px; /* Adjust this value to set the distance from the bottom */

  display: flex;
  align-items: center;
  font-size: 16px; /* Adjust font size as needed */
  color: #000; /* Color of the text */
}

.typing2 strong {
  margin-right: 8px; /* Spacing between text and dots */
}

.typing2 .dots {
  display: flex;
}

.typing2 .dots span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background-color: #000; /* Color of the dots */
  border-radius: 50%;
  animation: bounce 1.4s infinite;
}

.typing2 .dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing2 .dots span:nth-child(3) {
  animation-delay: 0.4s;
}

/*service slider css*/
.serviceImg {
  width: 180px !important;
}
.serviceImg {
  width: 100px; /* Adjust size as needed */
  height: auto;
  display: block;
  margin: 0 auto;
}

.serviceText {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.bar {
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  padding: 10px;
  margin: 50px 0px;
}
.bar > * {
  font-size: 17px;
  font-weight: 400;
}
.form-min-height {
  min-height: 350px;
}
.hidden {
  display: none;
}
@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.load {
  position: relative;
  z-index: 100;
}
.loader {
  position: fixed; /* Use fixed positioning to stay fixed in the viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050; /* Ensure it's on top of other content */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}
.insuranceSelection {
  background-color: #fc2e5a;
  color: #fff;
  padding: 5px 15px;
  border-radius: 8px;
}
.insuranceSelection:hover {
  cursor: pointer;
}

.modal-backdrop {
  z-index: 50 !important;
}

.modal {
  z-index: 100 !important;
}

.DatePicker_dayLabel__93j2P {
  font-size: 12px;
  margin: 4px 0 -4px 0px !important;
}

.selected {
  background-color: #fc2e5a !important;
  border: 0 !important;
}
.selected span {
  color: #fff !important;
}

.custom-confirm-button {
  background-color: #6495ed !important;
}

.AlreadyBooked:hover {
  cursor: pointer;
}

.Available:hover {
  cursor: pointer;
}
.NotAvailable:hover {
  cursor: pointer;
}

.autoComplete {
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 15px;
}

.custom-file-label {
  padding-top: 5px !important;
}

.DatePicker_container__6wziS .DatePicker_buttonWrapper__d7dlw:first-child {
  position: relative !important;
  left: -50px !important;
}

.DatePicker_container__6wziS .DatePicker_buttonWrapper__d7dlw:last-child {
  position: relative !important;
  right: -4px !important;
}

.TitleBox {
  display: none;
}

.cursorPointer:hover {
  cursor: pointer;
}

.DatePicker_dateLabel__LXXvL {
  font-size: 14px !important;
}

/* .DatePicker_dateDayItem__xelPz {
  font-weight: bold;
  width: 54px !important;
  height: 52px !important;
  border-radius: 50%;
  border: 2px solid rgb(54, 105, 238);
  color: rgb(54, 105, 238);
} */

.resonToVisit {
  text-wrap: nowrap;
  text-transform: capitalize;
}

.cursorPointer:hover {
  cursor: pointer;
}

.MyMessageWImg {
  cursor: pointer;
  background-color: #e1d5fe;
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  margin-left: auto;
  max-width: 350px;
  text-align: right;
  font-size: 14px !important;
}
.MyMessageWImg h5 {
  font-size: 14px !important;
}

.OtherMessageWImg {
  cursor: pointer;
  background-color: #e1d5fe;
  padding: 20px;
  border-radius: 0px 10px 10px 10px;

  max-width: 350px;
  text-align: left !important;
}

.OtherMessageWImg h5 {
  font-size: 14px !important;
}

.ChatBody {
  padding-bottom: 5rem;
}

.patient-side-agora-incoming-player {
  position: absolute;
  top: 0;
}
/* .ExpertBox {
  height: 100%;
}

.ExpertBox img {
  object-fit: cover;
  height: 100%;
} */
