/*......agora css......*/
#zsiq_float {
  right: 20px !important; /* Position it 20px from the right */
  left: auto !important; /* Remove any left alignment */
  bottom: 40px !important; /* Position it 40px from the bottom */
}

.button-group-agora {
  z-index: 100;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 50px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.start-call,
.end-call {
  border: 0;
  background-color: #ffffff00;
  margin-left: 10px;
}
.endcallIcon {
  border-radius: 50%;
  padding: 7px;
  color: #fff;
  background: red;
  font-size: 45px;
}

.mic {
  padding: 7px;
  background-color: #0000008c;
  border-radius: 50%;
  color: #fff;
  font-size: 45px;
}
/*.....................*/

/*..........caller patient screen css............*/

.join-call-screen {
  position: fixed; /* Fix position to cover full screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.852); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* High z-index to appear on top */
}

.caller-info {
  margin-bottom: 20px;
}

.caller-name {
  font-size: 24px;
  color: white;
  text-align: center;
}

.call-buttons {
  display: flex;
  gap: 20px; /* Space between buttons */
}

.call-buttons button {
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.accept-button {
  background-color: #4caf50; /* Green background for accept button */
  color: white;
}

.decline-button {
  background-color: #f44336; /* Red background for decline button */
  color: white;
}

/*......................*/

.care-plan-card {
  background-color: #fff;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
}

.care-plan-card > * {
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 17px;
}

.profile-pic {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.DatePicker_dateLabel__-uTnI {
  font-size: 13px !important;
}
